/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/swiper-bundle.css";

.flex-center {
    margin: 0px auto;
    width: 100%;
    height: 100%;
    display: flex; /* Magic begins */
    flex-direction: column;
    justify-content: center; /* Center in main axis */
    align-items: center; /* Center in cross axis */
}

.transparent-modal {
    // --background: rgba(10, 111, 219, 0.2);
    --background: rgba(34, 36, 40, 1);

    &::part(content) {
        backdrop-filter: blur(12px);
    }
}
